@use 'sass:meta';

////
/// @group form-controls
////

/// Color for disabled inputs.
$color-disabled: var(--color-neutral-500);

/// Color for placeholder.
$color-placeholder: var(--color-neutral-700);


/// Common styles for label element - specific to input, textarea, and select elements.
/// @example
///   @use '@kin/css/common/tools/mixins/form-controls';
///   @include form-controls.form-wrapper();
@mixin form-wrapper() {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}

/// Common styles for control (i.e., the actual input, textarea, select element) element - specific to input, textarea, and select elements.
/// @param {String} $wrapper-selector Class selector of wrapper element. E.g. '.input', '.textarea', '.select'.
/// @param {Boolean} $fixed-height [true] - By default height will be set to 2.5rem. Set this to false to set height to auto.
/// @example
///   @use '@kin/css/common/tools/mixins/form-controls';
///   @include form-controls.form-control();
@mixin form-control($wrapper-selector, $fixed-height: true) {
  display: block;
  overflow: hidden;
  width: 100%;
  padding: var(--space-300);
  border: 1px solid var(--color-neutral-700);
  border-radius: var(--border-radius-100);
  margin-top: var(--space-200);
  margin-bottom: 2px;
  appearance: none;
  background-color: transparent;
  box-shadow: 0 0 2px 2px transparent;
  color: var(--color-neutral-black);
  font-family: inherit;
  font-size: 1rem;
  font-weight: inherit;
  line-height: 1;
  text-overflow: ellipsis;

  @if $fixed-height {
    height: 3.375rem;
  }

  @else {
    height: auto;
  }

  #{$wrapper-selector}--sm & {
    @if $fixed-height {
      height: 2rem;
    }

    font-size: var(--font-size-100);
  }

  &:hover:not(:disabled) {
    outline: 1px solid var(--color-neutral-700);
  }

  &:focus:not(:disabled) {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &::placeholder {
    color: $color-placeholder;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    opacity: 1;
    visibility: visible;
  }

  &[readonly],
  &:disabled {
    background-color: var(--color-neutral-200);
    color: $color-disabled;
    opacity: 1;
    -webkit-text-fill-color: $color-disabled;
  }

  &:disabled {
    cursor: not-allowed;
  }


  .validated &:invalid,
  #{$wrapper-selector}--validated &:invalid,
  #{$wrapper-selector}--invalid & {
    border-color: var(--color-warning);
    outline: 1px solid var(--color-warning);
  }

  // On iOS, date inputs default to text-align center.
  // Align left to match our styling.
  &::-webkit-date-and-time-value {
    position: relative;
    top: var(--space-100);
    text-align: left;
  }
}

/// Common styles for faux-label (actual element used is a span) element - specific to input, textarea, and select elements.
/// @param {String} $wrapper-selector Class selector of wrapper element. E.g. '.input', '.textarea', '.select'.
/// @example
///   @use '@kin/css/common/tools/mixins/form-controls';
///   @include form-controls.form-label();
@mixin form-label($wrapper-selector) {
  display: inline-block;
  color: var(--color-neutral-900);
  font-size: 1rem;
  font-weight: var(--font-weight-semi-bold);
  line-height: 1;
  pointer-events: none;

  #{$wrapper-selector}__control[readonly]~&,
  #{$wrapper-selector}__control:disabled~& {
    color: $color-disabled;
  }

  #{$wrapper-selector}--sm & {
    font-size: var(--font-size-100);
  }

  #{$wrapper-selector}--sm #{$wrapper-selector}__control:focus~&,
  #{$wrapper-selector}--sm #{$wrapper-selector}__control:not(:placeholder-shown)~&,
  #{$wrapper-selector}--sm #{$wrapper-selector}__control--active~& {
    font-size: var(--font-size-0);
  }

  .validated #{$wrapper-selector}__control:invalid~&,
  .validated #{$wrapper-selector}__control:focus:invalid~&,
  .validated #{$wrapper-selector}__control:not(:placeholder-shown):invalid~&,
  .validated #{$wrapper-selector}__control--active:invalid~&,
  #{$wrapper-selector}--validated #{$wrapper-selector}__control:invalid~&,
  #{$wrapper-selector}--validated #{$wrapper-selector}__control:focus:invalid~&,
  #{$wrapper-selector}--validated #{$wrapper-selector}__control:not(:placeholder-shown):invalid~&,
  #{$wrapper-selector}--validated #{$wrapper-selector}__control--active:invalid~&,
  #{$wrapper-selector}--invalid &,
  #{$wrapper-selector}--invalid #{$wrapper-selector}__control:focus~&,
  #{$wrapper-selector}--invalid #{$wrapper-selector}__control:not(:placeholder-shown)~&,
  #{$wrapper-selector}--invalid #{$wrapper-selector}__control--active~& {
    color: var(--color-warning);
  }
}

/// Common styles for label element - specific to inputs with type of checkbox and radio.
/// @example
///   @use '@kin/css/common/tools/mixins/form-wrapper-check';
///   @include form-controls.form-label();
@mixin form-wrapper-check() {
  display: inline-flex;
  align-items: flex-start;
  gap: var(--space-200);
}

/// Common styles for control element - specific to inputs with type of checkbox and radio.
/// @example
///   @use '@kin/css/common/tools/mixins/form-controls';
///   @include form-controls.form-control-check();
@mixin form-control-check() {
  position: relative;
  width: var(--space-400);
  height: var(--space-400);
  flex: 1 0 auto;
  border: 2px solid var(--color-neutral-black);
  appearance: none;
  cursor: pointer;
  transition: all 250ms;

  &:focus:not(:disabled) {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:focus-within:not(:disabled) {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:disabled {
    border-color: var(--color-neutral-500);
    cursor: not-allowed;
  }
}

/// Common styles for faux-label element (actual element used is a span) - specific to inputs with type of checkbox and radio.
/// @param {String} $wrapper-selector Class selector of wrapper element. E.g. '.checkbox', '.radio'.
/// @example
///   @use '@kin/css/common/tools/mixins/form-controls';
///   @include form-controls.form-label-check();
@mixin form-label-check($wrapper-selector) {
  flex: 0 1 auto;
  cursor: pointer;
  font-size: var(--font-size-100);
  font-weight: var(--font-weight-semi-bold);

  #{$wrapper-selector}__control:disabled~& {
    color: var(--color-neutral-500);
  }

  #{$wrapper-selector}__control:disabled~& {
    cursor: not-allowed;
  }

  #{$wrapper-selector}--invalid &,
  .validated #{$wrapper-selector}__control:invalid~& {
    color: var(--color-warning);
  }
}
