@use '../../common/tools/mixins';
@use '../../common/settings/grid';


////
/// @group Spacing
////

/// CSS Spacing properties
$gaps: (
  'm': 'margin',
  'p': 'padding'
);

/// Directions for CSS Spacing properties
$directions: (
  'l': 'left',
  'r': 'right',
  't': 'top',
  'b': 'bottom',
  'a': 'all',
  'y': 'vertical',
  'x': 'horizontal',
);

/// Spacer values that correlate to our space tokens
$spacers : (0, 50, 75, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000);

@each $gapKey,
  $gapValue in $gaps {
  @each $space in $spacers {

    @each $name,
      $media-query in grid.$media-query-map {
      @if $name =='xs' {

        @each $dirKey,
          $dirValue in $directions {
          @if $dirKey =='a' {
            .#{$gapKey}-auto {
              #{$gapValue}: auto !important;
            }

            .#{$gapKey}-#{$space} {
              #{$gapValue}: var(--space-#{$space}) !important;
            }
          }

          @else if $dirKey =='x' {
            .#{$gapKey}-#{$dirKey}-auto {
              #{$gapValue}-right: auto !important;
              #{$gapValue}-left: auto !important;
            }

            .#{$gapKey}-#{$dirKey}-#{$space} {
              #{$gapValue}-right: var(--space-#{$space}) !important;
              #{$gapValue}-left: var(--space-#{$space}) !important;
            }
          }

          @else if $dirKey =='y' {
            .#{$gapKey}-#{$dirKey}-auto {
              #{$gapValue}-top: auto !important;
              #{$gapValue}-bottom: auto !important;
            }

            .#{$gapKey}-#{$dirKey}-#{$space} {
              #{$gapValue}-top: var(--space-#{$space}) !important;
              #{$gapValue}-bottom: var(--space-#{$space}) !important;
            }
          }

          @else {
            .#{$gapKey}-#{$dirKey}-auto {
              #{$gapValue}-#{$dirValue}: auto !important;
            }

            .#{$gapKey}-#{$dirKey}-#{$space} {
              #{$gapValue}-#{$dirValue}: var(--space-#{$space}) !important;
            }
          }
        }
      }

      @else {
        @include mixins.media-query($name) {

          @each $dirKey,
            $dirValue in $directions {
            @if $dirKey =='a' {
              .#{$gapKey}-auto\@#{$name} {
                #{$gapValue}: auto !important;
              }

              .#{$gapKey}-#{$space}\@#{$name} {
                #{$gapValue}: var(--space-#{$space}) !important;
              }
            }

            @else if $dirKey =='x' {
              .#{$gapKey}-#{$dirKey}-auto\@#{$name} {
                #{$gapValue}-right: auto !important;
                #{$gapValue}-left: auto !important;
              }

              .#{$gapKey}-#{$dirKey}-#{$space}\@#{$name} {
                #{$gapValue}-right: var(--space-#{$space}) !important;
                #{$gapValue}-left: var(--space-#{$space}) !important;
              }
            }

            @else if $dirKey =='y' {
              .#{$gapKey}-#{$dirKey}-auto\@#{$name} {
                #{$gapValue}-top: auto !important;
                #{$gapValue}-bottom: auto !important;
              }

              .#{$gapKey}-#{$dirKey}-#{$space}\@#{$name} {
                #{$gapValue}-top: var(--space-#{$space}) !important;
                #{$gapValue}-bottom: var(--space-#{$space}) !important;
              }
            }

            @else {
              .#{$gapKey}-#{$dirValue}-auto\@#{$name} {
                #{$gapValue}-#{$dirValue}: auto !important;
              }

              .#{$gapKey}-#{$dirKey}-#{$space}\@#{$name} {
                #{$gapValue}-#{$dirValue}: var(--space-#{$space}) !important;
              }
            }
          }
        }
      }
    }
  }
}
