@use '../../mixins/form-controls';
@use '../../../common/tools/mixins/grid';

////
/// @group form-controls
////

/// @example
///   <label class="radio">
///     <input class="radio__control" type="radio">
///     <span class="radio__label">Label</span>
///   </label>
.radio {
  @include form-controls.form-wrapper-check();

  &__control {
    @include form-controls.form-control-check();
    border-radius: var(--border-radius-full);

    &:hover:not(:disabled) {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: var(--space-200);
        height: var(--space-200);
        border-radius: var(--border-radius-full);
        background-color: var(--color-primary);
        content: '';
        transform: translate(-50%, -50%);
      }
    }

    &:active {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: var(--space-200);
        height: var(--space-200);
        border-radius: var(--border-radius-full);
        background-color: var(--color-neutral-black);
        content: '';
        transform: translate(-50%, -50%);
      }
    }

    &:checked {
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: var(--space-200);
        height: var(--space-200);
        border-radius: var(--border-radius-full);
        background-color: var(--color-neutral-black);
        content: '';
        transform: translate(-50%, -50%);
      }

      &:disabled {
        border-color: var(--color-neutral-500);

        &::before {
          background-color: var(--color-neutral-500);
        }
      }
    }
  }

  &__label {
    @include form-controls.form-label-check('.radio');

  }
}
