@use '../../mixins/form-controls';
@use '../../../common/tools/mixins/grid';

////
/// @group form-controls
////

/// @example
///   <label class="checkbox">
///     <input class="checkbox__control" type="checkbox">
///     <span class="checkbox__label">Label</span>
///   </label>
.checkbox {
  @include form-controls.form-wrapper-check();

  &__control {
    @include form-controls.form-control-check();
    border-radius: var(--border-radius-200);

    &:hover:not(:disabled, :checked) {
      border-color: var(--color-neutral-black);
      background-color: var(--color-primary-400);
    }

    &:checked {
      border-color: var(--color-neutral-black);
      background-color: var(--color-primary);

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        color: var(--color-neutral-black);
        content: '\2713'; // unicode checkmark `✓`
        font-size: var(--font-size-0);
        font-weight: var(--font-weight-bold);
        transform: translate(-50%, -50%);
      }

      &:disabled {
        border-color: var(--color-neutral-500);
        background-color: var(--color-neutral-200);
      }
    }
  }

  &__label {
    @include form-controls.form-label-check('.checkbox');
  }
}
