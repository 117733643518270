.form-group {
  &__fieldset {
    padding: 0;
    border: 0;
    margin: 0;
    box-shadow: none;
  }

  &__legend {
    display: block;
    width: 100%;
    padding: 0;
    border: 0;
    margin: 0 0 var(--space-400);
    font-size: var(--font-size-100);
    font-weight: var(--font-weight-semi-bold);

    .form-group__fieldset:disabled & {
      color: var(--color-neutral-500);
    }
  }

  &__controls {
    display: block;

    > :not(:last-child) {
      margin-right: var(--space-500);
    }

    /* stylelint-disable max-nesting-depth */
    .form-group--vertical & {
      > {
        * {
          display: flex;
        }

        :not(:last-child) {
          margin-right: 0;
          margin-bottom: var(--space-500);
        }
      }
    }

    /* stylelint-enable max-nesting-depth */
  }
}
