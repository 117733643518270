@use '../../common/tools/mixins';
@use '../../common/settings/grid';

// Font Family Classes
.ff-sans {
  font-family: var(--font-family-webfont) !important;
}

.ff-system-sans {
  font-family: var(--font-family-system-sans) !important;
}

.ff-system-serif {
  font-family: var(--font-family-system-serif) !important;
}

.ff-system-mono {
  font-family: var(--font-family-system-mono) !important;
}

.ff-body {
  font-family: var(--font-family-body) !important;
}

.ff-header {
  font-family: var(--font-family-header) !important;
}

.ff-public {
  font-family: var(--font-family-public) !important;
}

// Heading Classes
.h1 {
  font-size: var(--font-size-heading-xxl) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: var(--line-height-heading) !important;
}

.h2 {
  font-size: var(--font-size-heading-xl) !important;
  font-weight: var(--font-weight-bold) !important;
  line-height: var(--line-height-heading) !important;
}

.h3 {
  font-size: var(--font-size-heading-lg) !important;
  font-weight: var(--font-weight-semi-bold) !important;
  line-height: var(--line-height-heading) !important;
}

.h4 {
  font-size: var(--font-size-heading-md) !important;
  line-height: var(--line-height-body) !important;
}

.h5 {
  font-size: var(--font-size-heading-sm) !important;
  line-height: var(--line-height-body) !important;
}

.h6 {
  font-size: var(--font-size-heading-xs) !important;
  line-height: var(--line-height-body) !important;
}

// Display Styles

.fs-display-sm {
  font-size: var(--font-size-display-sm) !important;
}

.fs-display-md {
  font-size: var(--font-size-display-md) !important;
}

.fs-display-lg {
  font-size: var(--font-size-display-lg) !important;
}

// Body Font Size

.fs-body-xs {
  font-size: var(--font-size-body-xs) !important;
}

.fs-body-sm {
  font-size: var(--font-size-body-sm) !important;
}

.fs-body-md {
  font-size: var(--font-size-body-md) !important;
}

.fs-body-lg {
  font-size: var(--font-size-body-lg) !important;
}

// Text styles
.fw-light {
  font-weight: var(--font-weight-light) !important;
}

.fw-regular {
  font-weight: var(--font-weight-regular) !important;
}

.fw-medium {
  font-weight: var(--font-weight-medium) !important;
}

.fw-semibold {
  font-weight: var(--font-weight-semibold) !important;
}

.fw-bold {
  font-weight: var(--font-weight-bold) !important;
}

.fw-black {
  font-weight: var(--font-weight-black) !important;
}

.fs-italic {
  font-style: italic !important;
}

.tt-uppercase {
  text-transform: uppercase !important;
}

.tt-lowercase {
  text-transform: lowercase !important;
}

.tt-capitalize {
  text-transform: capitalize !important;
}

.td-underline {
  text-decoration: underline !important;
}

.td-none {
  text-decoration: none !important;
}

.ws-no-wrap {
  white-space: nowrap !important;
}

.ws-normal {
  white-space: normal !important;
}

// Text Align

@each $name,
  $media-query in grid.$media-query-map {
  @if $name =='xs' {
    .ta-left {
      text-align: left !important;
    }

    .ta-right {
      text-align: right !important;
    }

    .ta-center {
      text-align: center !important;
    }
  }

  @else {
    @include mixins.media-query($name) {
      .ta-left\@#{$name} {
        text-align: left !important;
      }

      .ta-right\@#{$name} {
        text-align: right !important;
      }

      .ta-center\@#{$name} {
        text-align: center !important;
      }
    }
  }
}

// Line Height

.lh-body {
  line-height: var(--line-height-body) !important;
}

.lh-heading {
  line-height: var(--line-height-heading) !important;
}

.lh-sub-heading {
  line-height: var(--line-height-sub-heading) !important;
}

.lh-reset {
  line-height: var(--line-height-reset) !important;
}
