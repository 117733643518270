@use '../../common/tools/mixins';
@use '../../common/settings/grid';


// Position Classes
.pos-static {
  position: static !important;
}

.pos-relative {
  position: relative !important;
}

.pos-absolute {
  position: absolute !important;
}

.pos-fixed {
  position: fixed !important;
}

.pos-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

// Alignment Classes
.v-a-middle {
  vertical-align: middle !important;
}

.v-a-top {
  vertical-align: top !important;
}

.v-a-bottom {
  vertical-align: bottom !important;
}

.v-a-text-top {
  vertical-align: text-top !important;
}

.v-a-text-bottom {
  vertical-align: text-bottom !important;
}

.v-a-baseline {
  vertical-align: baseline !important;
}

// Overflow utilities
.of-hidden {
  overflow: hidden !important;
}

.of-scroll {
  overflow: scroll !important;
}

.of-auto {
  overflow: auto !important;
}

.of-visible {
  overflow: visible !important;
}
