@use '../../common/tools/mixins';
@use '../../common/settings/grid';


////
/// @group Display
////

/// Display properties
$display-properties: (
  block,
  flex,
  inline,
  inline-block,
  inline-flex,
  none,
  table,
  table-cell
);

@each $name,
  $media-query in grid.$media-query-map {

  @if $name =='xs' {
    @each $display in $display-properties {
      .d-#{$display} {
        display: $display !important;
      }
    }
  }

  @else {
    @include mixins.media-query($name) {
      @each $display in $display-properties {
        .d-#{$display}\@#{$name} {
          display: $display !important;
        }
      }
    }
  }
}

.table-fixed {
  table-layout: fixed !important;
}

.v-hidden {
  visibility: hidden !important;
}

.v-visible {
  visibility: visible !important;
}

.sr-only {
  @include mixins.sr-only;
}
