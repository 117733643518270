/**
 * Kin's reset
 * Inspiration from:
 *   - Andy Bell - https://piccalil.li/blog/a-modern-css-reset/
 *   - Elly Loel - https://www.ellyloel.com/projects/modern-css-reset/
 * Notes:
 *   - `:where()` is used to lower specificity for easy overriding.
*/

/* Use a more-intuitive box-sizing model on everything */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin padding */
* {
  padding: 0;
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Position list marker inside */
:where(ul, ol) {
  list-style: none;
  list-style-position: inside;
}

/* Make media easier to work with */
:where(img, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(img, video) {
  max-width: 100%;
  height: auto;
}

/* Inherit fonts for inputs and buttons */
:where(input, button, textarea, select) {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}

/** Make it clear that interactive elements are interactive */
/* stylelint-disable selector-no-qualifying-type */
:where(a[href], button, summary, [tabindex]:not([tabindex*='-'])) {
  cursor: pointer;
  touch-action: manipulation;
}

:where(input[type='file']) {
  cursor: auto;
}

:where(input[type='file'])::-webkit-file-upload-button,
:where(input[type='file'])::file-selector-button {
  cursor: pointer;
}

/** Disabled cursor for disabled buttons */
:where(button, button[type], input[type='button'], input[type='submit'], input[type='reset'])[disabled] {
  cursor: not-allowed;
}

/* stylelint-enable selector-no-qualifying-type */
