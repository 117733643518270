html,
body {
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-black);
  font-family: var(--font-family-default);
  font-size: var(--font-size-body-md);
  line-height: var(--line-height-body);
}

body {
  width: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: var(--font-size-heading-xxl);
  line-height: var(--line-height-heading);
}

h2 {
  font-size: var(--font-size-heading-xl);
  line-height: var(--line-height-heading);
}

h3 {
  font-size: var(--font-size-heading-lg);
  line-height: var(--line-height-heading);
}

h4 {
  font-size: var(--font-size-heading-md);
  line-height: var(--line-height-heading);
}

h5 {
  font-size: var(--font-size-heading-sm);
  line-height: var(--line-height-heading);
}

h6 {
  font-size: var(--font-size-heading-xs);
  line-height: var(--line-height-heading);
}

p {
  color: var(--color-neutral-black);
  font-size: var(--font-size-body-md);
  line-height: var(--line-height-body);
}

ul {
  li {
    color: var(--color-neutral-black);
    font-size: var(--font-size-body-md);
    line-height: var(--line-height-body);
  }
}
