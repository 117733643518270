.card {
  position: relative;
  display: block;
  padding: 1.25rem;
  border: 1px solid var(--color-neutral-300);
  border-radius: var(--border-radius-300);
  background: var(--color-neutral-white);

  &--info,
  &--disabled {
    border-color: var(--color-neutral-300);
    background: var(--color-neutral-200);
  }

  &--primary,
  &--callout {
    border-color: transparent;
    background: var(--color-primary-300);
  }

  &--warning {
    border-color: var(--color-warning-400);
    background: var(--color-warning-100);
  }

  &--secondary {
    border-color: var(--color-secondary-400);
    background: var(--color-secondary-100);
  }

  &--clickable {
    cursor: pointer;
    transition: transform 0.125s ease-in-out, border-color 0.125s ease-in-out;
    will-change: auto;

    &:hover,
    &:focus {
      border-color: var(--color-secondary-600);
      transform: translateY(-2%);
    }
  }

  &--full-size {
    max-width: 100%;
    height: 100%;
  }

  &--small {
    padding: 0;
  }

  &--medium {
    padding: var(--space-600);
  }

  &--large {
    padding: var(--space-800);
  }

  &--x-large {
    padding: var(--space-1000);
  }
}
