.radio-card {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  padding: 1rem;
  border: 1px solid var(--color-neutral-700);
  border-radius: 4px;
  cursor: pointer;

  &--block {
    width: 100%;
    justify-content: center;
  }

  &__header {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
  }

  &__header:not(:has(.radio-card__title)) {
    justify-content: center;
  }

  &__title {
    flex: 2 1 100%;
    margin-top: var(--space-75);
    font-weight: 700;
    line-height: 1;
  }

  &__control {
    appearance: none;
  }

  &__description {
    margin-top: 1rem;
  }

  &:hover:not(:disabled) {
    background-color: var(--color-primary-200);
    outline: 1px solid var(--color-neutral-700);
  }

  &:focus:not(:disabled) {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:has(.radio-card__control:checked) {
    background-color: var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  .validated &:invalid,
  &--validated &:invalid,
  &--invalid {
    border-color: var(--color-warning);
    outline: 1px solid var(--color-warning);
  }
}
