.stack {
  display: flex;
  flex-direction: column;
  gap: var(--space-200);
}

.stack--compact {
  display: flex;
  flex-direction: column;
  gap: var(--space-100);
}

.stack--open {
  display: flex;
  flex-direction: column;
  gap: var(--space-400);
}
