@use 'sass:map';
@use '../../settings/grid';

////
/// @group grid
////

/// Media query mixin for our standard breakpoints.
/// @param {sm | md | lg | xl} $size
/// @throw Throws an error when $size is not sm, md, lg, or xl
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   p {
///     font-size: 1rem;
///
///     @include mixins.media-query(sm) {
///       font-size: 1.25rem;
///     }
///
///     @include mixins.media-query(md) {
///       font-size: 1.5rem;
///     }
///
///     @include mixins.media-query(lg) {
///       font-size: 1.75rem;
///     }
///
///     @include mixins.media-query(xl) {
///       font-size: 2rem;
///     }
///   }
@mixin media-query($size) {
  $media-query: map.get(grid.$media-query-map, $size);

  @if $media-query ==null {
    @error 'Expected parameter $size to be sm, md, lg, or xl.';
  }

  @media #{$media-query} {
    @content;
  }
}

/// Media query mixin for sm breakpoint
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   p {
///     font-size: 1rem;
///
///     @include mixins.sm {
///       font-size: 1.25rem;
///     }
///   }
@mixin sm {
  @include media-query(sm) {
    @content;
  }
}

/// Media query mixin for md breakpoint.
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   p {
///     font-size: 1rem;
///
///     @include mixins.md {
///       font-size: 1.5rem;
///     }
///   }
@mixin md {
  @include media-query(md) {
    @content;
  }
}

/// Media query mixin for lg breakpoint.
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   p {
///     font-size: 1rem;
///
///     @include mixins.lg {
///       font-size: 1.75rem;
///     }
///   }
@mixin lg {
  @include media-query(lg) {
    @content;
  }
}

/// Media query mixin for xl breakpoint.
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   p {
///     font-size: 1rem;
///
///     @include mixins.xl {
///       font-size: 2rem;
///     }
///   }
@mixin xl {
  @include media-query(xl) {
    @content;
  }
}

/// Container mixin for creating semantic grid.
/// @param {Number} $max-width
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .nav {
///     background-color: #ccc;
///
///     &__container {
///       @include mixins.container;
///     }
///
///     &__list {
///       list-style-type: none;
///     }
///   }
@mixin container($max-width: 96rem) {
  max-width: $max-width;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

/// Row mixin for creating semantic grid.
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .nav {
///     background-color: #ccc;
///
///     &__row {
///       @include mixins.row;
///     }
///   }
@mixin row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

/// Col mixin for creating semantic grid.
/// @param {Number} $max-width
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .nav {
///     background-color: #ccc;
///
///     &__col {
///       @include mixins.col(50%);
///     }
///   }
@mixin col($max-width) {
  max-width: $max-width;
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-basis: $max-width;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
