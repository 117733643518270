/* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
.table-wrapper {
  overflow-x: auto;
  scroll-snap-type: both mandatory;

  table,
  .table {
    display: table;
    overflow: auto;
    width: 100%;
    border-collapse: collapse;

    caption {
      margin-bottom: var(--space-100);
      font-size: var(--font-size-heading-xs);
      font-weight: var(--font-weight-bold);
      text-align: left;
    }

    thead {
      width: 100%;

      tr {
        background: var(--color-neutral-200);
        text-align: left;
        vertical-align: bottom;

        th {
          padding: var(--space-200) var(--space-300);
          border-top: 1px solid var(--color-neutral-400);
          border-bottom: 1px solid var(--color-neutral-400);
          color: var(--color-neutral-black);
          font-size: var(--font-size-body-sm);
          font-weight: var(--font-weight-black);
          line-height: var(--line-height-200);
          scroll-snap-align: start;
        }
      }

      &.sticky {
        th {
          position: sticky;
          z-index: var(--elevation-200);
          top: 0;
          background: var(--color-neutral-200);
        }
      }
    }

    tbody {
      &.sticky {
        th {
          position: sticky;
          z-index: var(--elevation-100);
          left: 0;
          scroll-snap-align: start;
        }
      }

      tr {
        background: inherit;
        text-align: left;
        vertical-align: middle;

        &:nth-of-type(even) {
          background: var(--color-neutral-200);
        }

        &:hover {
          background: var(--color-primary-100);
        }

        th {
          padding: var(--space-100) var(--space-300);
          border-bottom: 1px solid var(--color-neutral-300);
          background: var(--color-neutral-200);
          font-size: var(--font-size-body-sm);
          font-weight: var(--font-weight-black);
          line-height: var(--line-height-200);
        }

        td {
          padding: var(--space-100) var(--space-300);
          border-bottom: 1px solid var(--color-neutral-300);
          font-size: var(--font-size-body-sm);
          scroll-snap-align: start;
        }
      }
    }

    tfoot {
      tr {
        height: 2rem;
        background: var(--color-neutral-white);
        text-align: left;
        vertical-align: middle;

        th {
          padding: var(--space-200) var(--space-300);
          font-size: var(--font-size-body-sm);
          font-weight: bold;
        }

        td {
          padding: var(--space-100) var(--space-300);
          border: 0;
          font-size: var(--font-size-body-sm);
          font-weight: bold;
        }
      }
    }

    &.table--compact {
      thead {
        th {
          padding: var(--space-75) var(--space-75);
        }
      }

      tbody {
        tr {
          th,
          td {
            padding: var(--space-50) var(--space-75);
          }
        }
      }

      tfoot {
        tr {
          th,
          td {
            padding: var(--space-50) var(--space-75);
          }
        }
      }
    }

    &.table--open {
      thead {
        th {
          padding: var(--space-300) var(--space-400);
        }
      }

      tbody {
        tr {
          th,
          td {
            padding: var(--space-200) var(--space-400);
          }
        }
      }

      tfoot {
        tr {
          th,
          td {
            padding: var(--space-200) var(--space-400);
          }
        }
      }
    }

    &.table--unstriped {
      tbody {
        tr {
          &:nth-of-type(odd) {
            background: inherit;
          }

          &:nth-of-type(even) {
            background: inherit;
          }

          &:hover {
            background: var(--color-primary-100);
          }
        }
      }
    }

    &.table--bordered {
      border: 1px solid var(--color-neutral-700);

      thead {
        th {
          border: 1px solid var(--color-neutral-700);
        }
      }

      tbody {
        tr {
          th,
          td {
            border: 1px solid var(--color-neutral-700);
          }
        }
      }
    }

    &.table--fixed {
      table-layout: fixed;
    }
  }
}
/* stylelint-enable selector-max-compound-selectors, max-nesting-depth */
