////
/// @group helpers
////
/// 

/// Useful in areas like tables and lists with alternating background colors.
/// @param {Color} $background-color
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .striped-list {
///     list-style-type: none;
///
///     li {
///       @include mixins.alternate-background(#ccc);
///     }
///   }
@mixin alternate-background($background-color) {
  &:nth-of-type(odd) {
    background-color: $background-color;
  }
}

/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .loader {
///     @include mixins.center-absolute-x;
///   }
@mixin center-absolute-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .loader {
///     @include mixins.center-absolute-y;
///   }
@mixin center-absolute-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .loader {
///     @include mixins.center-absolute;
///   }
@mixin center-absolute {
  @include center-absolute-x();
  @include center-absolute-y();
  transform: translate(-50%, -50%);
}

/// Create invisible content just for screen readers.
/// @link https://webaim.org/techniques/css/invisiblecontent/ Invisible Content Just for Screen Reader Users
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .sr-only,
///   .visually-hidden {
///     @include mixins.sr-only;
///   }
@mixin sr-only {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

/// Truncates text with ellipsis.
/// @param {Number} $max-width
/// @example
///   @use '@kin/css/common/tools/mixins';
///
///   .truncated-text {
///     @include mixins.truncate(12.5rem);
///   }
/// }
@mixin truncate($max-width) {
  overflow: hidden;
  width: $max-width;
  text-overflow: ellipsis;
  white-space: nowrap;
}
