// Primary Classes
.bg-primary,
.h\:bg-primary:hover,
.f\:bg-primary:focus {
  background-color: var(--color-primary) !important;
}

.bg-primary-100,
.h\:bg-primary-100:hover,
.f\:bg-primary-100:focus {
  background-color: var(--color-primary-100) !important;
}

.bg-primary-200,
.h\:bg-primary-200:hover,
.f\:bg-primary-200:focus {
  background-color: var(--color-primary-200) !important;
}

.bg-primary-400,
.h\:bg-primary-400:hover,
.f\:bg-primary-400:focus {
  background-color: var(--color-primary-400) !important;
}

.bg-primary-500,
.h\:bg-primary-500:hover,
.f\:bg-primary-500:focus {
  background-color: var(--color-primary-500) !important;
}

.bg-primary-900,
.h\:bg-primary-900:hover,
.f\:bg-primary-900:focus {
  background-color: var(--color-primary-900) !important;
}

.c-primary,
.h\:c-primary:hover,
.f\:c-primary:focus {
  color: var(--color-primary) !important;
}

.c-primary-100,
.h\:c-primary-100:hover,
.f\:c-primary-100:focus {
  color: var(--color-primary-100) !important;
}

.c-primary-200,
.h\:c-primary-200:hover,
.f\:c-primary-200:focus {
  color: var(--color-primary-200) !important;
}

.c-primary-400,
.h\:c-primary-400:hover,
.f\:c-primary-400:focus {
  color: var(--color-primary-400) !important;
}

.c-primary-500,
.h\:c-primary-500:hover,
.f\:c-primary-500:focus {
  color: var(--color-primary-500) !important;
}

.c-primary-900,
.h\:c-primary-900:hover,
.f\:c-primary-900:focus {
  color: var(--color-primary-900) !important;
}

.bc-primary,
.h\:bc-primary:hover,
.f\:bc-primary:focus {
  border-color: var(--color-primary) !important;
}

.bc-primary-100,
.h\:bc-primary-100:hover,
.f\:bc-primary-100:focus {
  border-color: var(--color-primary-100) !important;
}

.bc-primary-200,
.h\:bc-primary-200:hover,
.f\:bc-primary-200:focus {
  border-color: var(--color-primary-200) !important;
}

.bc-primary-400,
.h\:bc-primary-400:hover,
.f\:bc-primary-400:focus {
  border-color: var(--color-primary-400) !important;
}

.bc-primary-500,
.h\:bc-primary-500:hover,
.f\:bc-primary-500:focus {
  border-color: var(--color-primary-500) !important;
}

.bc-primary-900,
.h\:bc-primary-900:hover,
.f\:bc-primary-900:focus {
  border-color: var(--color-primary-900) !important;
}

// Secondary Classes
.bg-secondary,
.h\:bg-secondary:hover,
.f\:bg-secondary:focus {
  background-color: var(--color-secondary) !important;
}

.bg-secondary-100,
.h\:bg-secondary-100:hover,
.f\:bg-secondary-100:focus {
  background-color: var(--color-secondary-100) !important;
}

.bg-secondary-200,
.h\:bg-secondary-200:hover,
.f\:bg-secondary-200:focus {
  background-color: var(--color-secondary-200) !important;
}

.bg-secondary-300,
.h\:bg-secondary-300:hover,
.f\:bg-secondary-300:focus {
  background-color: var(--color-secondary-300) !important;
}

.bg-secondary-400,
.h\:bg-secondary-400:hover,
.f\:bg-secondary-400:focus {
  background-color: var(--color-secondary-400) !important;
}

.bg-secondary-500,
.h\:bg-secondary-500:hover,
.f\:bg-secondary-500:focus {
  background-color: var(--color-secondary-500) !important;
}

.bg-secondary-600,
.h\:bg-secondary-600:hover,
.f\:bg-secondary-600:focus {
  background-color: var(--color-secondary-600) !important;
}

.bg-secondary-700,
.h\:bg-secondary-700:hover,
.f\:bg-secondary-700:focus {
  background-color: var(--color-secondary-700) !important;
}

.bg-secondary-800,
.h\:bg-secondary-800:hover,
.f\:bg-secondary-800:focus {
  background-color: var(--color-secondary-800) !important;
}

.c-secondary,
.h\:c-secondary:hover,
.f\:c-secondary:focus {
  color: var(--color-secondary) !important;
}

.c-secondary-100,
.h\:c-secondary-100:hover,
.f\:c-secondary-100:focus {
  color: var(--color-secondary-100) !important;
}

.c-secondary-200,
.h\:c-secondary-200:hover,
.f\:c-secondary-200:focus {
  color: var(--color-secondary-200) !important;
}

.c-secondary-300,
.h\:c-secondary-300:hover,
.f\:c-secondary-300:focus {
  color: var(--color-secondary-300) !important;
}

.c-secondary-400,
.h\:c-secondary-400:hover,
.f\:c-secondary-400:focus {
  color: var(--color-secondary-400) !important;
}

.c-secondary-500,
.h\:c-secondary-500:hover,
.f\:c-secondary-500:focus {
  color: var(--color-secondary-500) !important;
}

.c-secondary-600,
.h\:c-secondary-600:hover,
.f\:c-secondary-600:focus {
  color: var(--color-secondary-600) !important;
}

.c-secondary-700,
.h\:c-secondary-700:hover,
.f\:c-secondary-700:focus {
  color: var(--color-secondary-700) !important;
}

.c-secondary-800,
.h\:c-secondary-800:hover,
.f\:c-secondary-800:focus {
  color: var(--color-secondary-800) !important;
}

.bc-secondary,
.h\:bc-secondary:hover,
.f\:bc-secondary:focus {
  border-color: var(--color-secondary) !important;
}

.bc-secondary-100,
.h\:bc-secondary-100:hover,
.f\:bc-secondary-100:focus {
  border-color: var(--color-secondary-100) !important;
}

.bc-secondary-200,
.h\:bc-secondary-200:hover,
.f\:bc-secondary-200:focus {
  border-color: var(--color-secondary-200) !important;
}

.bc-secondary-300,
.h\:bc-secondary-300:hover,
.f\:bc-secondary-300:focus {
  border-color: var(--color-secondary-300) !important;
}

.bc-secondary-400,
.h\:bc-secondary-400:hover,
.f\:bc-secondary-400:focus {
  border-color: var(--color-secondary-400) !important;
}

.bc-secondary-500,
.h\:bc-secondary-500:hover,
.f\:bc-secondary-500:focus {
  border-color: var(--color-secondary-500) !important;
}

.bc-secondary-600,
.h\:bc-secondary-600:hover,
.f\:bc-secondary-600:focus {
  border-color: var(--color-secondary-600) !important;
}

.bc-secondary-700,
.h\:bc-secondary-700:hover,
.f\:bc-secondary-700:focus {
  border-color: var(--color-secondary-700) !important;
}

.bc-secondary-800,
.h\:bc-secondary-800:hover,
.f\:bc-secondary-800:focus {
  border-color: var(--color-secondary-800) !important;
}

// Tertiary Colors
.bg-tertiary,
.h\:bg-tertiary:hover,
.f\:bg-tertiary:focus {
  background-color: var(--color-tertiary) !important;
}

.bg-tertiary-100,
.h\:bg-tertiary-100:hover,
.f\:bg-tertiary-100:focus {
  background-color: var(--color-tertiary-100) !important;
}

.bg-tertiary-200,
.h\:bg-tertiary-200:hover,
.f\:bg-tertiary-200:focus {
  background-color: var(--color-tertiary-200) !important;
}

.bg-tertiary-400,
.h\:bg-tertiary-400:hover,
.f\:bg-tertiary-400:focus {
  background-color: var(--color-tertiary-400) !important;
}

.bg-tertiary-600,
.h\:bg-tertiary-600:hover,
.f\:bg-tertiary-600:focus {
  background-color: var(--color-tertiary-600) !important;
}

.c-tertiary,
.h\:c-tertiary:hover,
.f\:c-tertiary:focus {
  color: var(--color-tertiary) !important;
}

.c-tertiary-100,
.h\:c-tertiary-100:hover,
.f\:c-tertiary-100:focus {
  color: var(--color-tertiary-100) !important;
}

.c-tertiary-200,
.h\:c-tertiary-200:hover,
.f\:c-tertiary-200:focus {
  color: var(--color-tertiary-200) !important;
}

.c-tertiary-400,
.h\:c-tertiary-400:hover,
.f\:c-tertiary-400:focus {
  color: var(--color-tertiary-400) !important;
}

.c-tertiary-600,
.h\:c-tertiary-600:hover,
.f\:c-tertiary-600:focus {
  color: var(--color-tertiary-900) !important;
}

.bc-tertiary,
.h\:bc-tertiary:hover,
.f\:bc-tertiary:focus {
  border-color: var(--color-tertiary) !important;
}

.bc-tertiary-100,
.h\:bc-tertiary-100:hover,
.f\:bc-tertiary-100:focus {
  border-color: var(--color-tertiary-100) !important;
}

.bc-tertiary-200,
.h\:bc-tertiary-200:hover,
.f\:bc-tertiary-200:focus {
  border-color: var(--color-tertiary-200) !important;
}

.bc-tertiary-400,
.h\:bc-tertiary-400:hover,
.f\:bc-tertiary-400:focus {
  border-color: var(--color-tertiary-400) !important;
}

.bc-tertiary-600,
.h\:bc-tertiary-600:hover,
.f\:bc-tertiary-600:focus {
  border-color: var(--color-tertiary-900) !important;
}

// Blue classes
.bg-blue,
.h\:bg-blue:hover,
.f\:bg-blue:focus {
  background-color: var(--color-blue) !important;
}

.bg-blue-100,
.h\:bg-blue-100:hover,
.f\:bg-blue-100:focus {
  background-color: var(--color-blue-100) !important;
}

.bg-blue-400,
.h\:bg-blue-400:hover,
.f\:bg-blue-400:focus {
  background-color: var(--color-blue-400) !important;
}

.c-blue,
.h\:c-blue:hover,
.f\:c-blue:focus {
  color: var(--color-blue) !important;
}

.c-blue-100,
.h\:c-blue-100:hover,
.f\:c-blue-100:focus {
  color: var(--color-blue-100) !important;
}

.c-blue-400,
.h\:c-blue-400:hover,
.f\:c-blue-400:focus {
  color: var(--color-blue-400) !important;
}

.bc-blue,
.h\:bc-blue:hover,
.f\:bc-blue:focus {
  border-color: var(--color-blue) !important;
}

.bc-blue-100,
.h\:bc-blue-100:hover,
.f\:bc-blue-100:focus {
  border-color: var(--color-blue-100) !important;
}

.bc-blue-400,
.h\:bc-blue-400:hover,
.f\:bc-blue-400:focus {
  border-color: var(--color-blue-400) !important;
}

// Warning Classes
.bg-warning,
.h\:bg-warning:hover,
.f\:bg-warning:focus {
  background-color: var(--color-warning) !important;
}

.bg-warning-100,
.h\:bg-warning-100:hover,
.f\:bg-warning-100:focus {
  background-color: var(--color-warning-100) !important;
}

.bg-warning-200,
.h\:bg-warning-200:hover,
.f\:bg-warning-200:focus {
  background-color: var(--color-warning-200) !important;
}

.bg-warning-400,
.h\:bg-warning-400:hover,
.f\:bg-warning-400:focus {
  background-color: var(--color-warning-400) !important;
}

.bg-warning-600,
.h\:bg-warning-600:hover,
.f\:bg-warning-600:focus {
  background-color: var(--color-warning-600) !important;
}

.c-warning,
.h\:c-warning:hover,
.f\:c-warning:focus {
  color: var(--color-warning) !important;
}

.c-warning-100,
.h\:c-warning-100:hover,
.f\:c-warning-100:focus {
  color: var(--color-warning-100) !important;
}

.c-warning-200,
.h\:c-warning-200:hover,
.f\:c-warning-200:focus {
  color: var(--color-warning-200) !important;
}

.c-warning-400,
.h\:c-warning-400:hover,
.f\:c-warning-400:focus {
  color: var(--color-warning-400) !important;
}

.c-warning-600,
.h\:c-warning-600:hover,
.f\:c-warning-600:focus {
  color: var(--color-warning-600) !important;
}

.bc-warning,
.h\:bc-warning:hover,
.f\:bc-warning:focus {
  border-color: var(--color-warning) !important;
}

.bc-warning-100,
.h\:bc-warning-100:hover,
.f\:bc-warning-100:focus {
  border-color: var(--color-warning-100) !important;
}

.bc-warning-200,
.h\:bc-warning-200:hover,
.f\:bc-warning-200:focus {
  border-color: var(--color-warning-200) !important;
}

.bc-warning-400,
.h\:bc-warning-400:hover,
.f\:bc-warning-400:focus {
  border-color: var(--color-warning-400) !important;
}

.bc-warning-600,
.h\:bc-warning-600:hover,
.f\:bc-warning-600:focus {
  border-color: var(--color-warning-600) !important;
}

// Success Classes
.bg-success,
.h\:bg-success:hover,
.f\:bg-success:focus {
  background-color: var(--color-success) !important;
}

.bg-success-100,
.h\:bg-success-100:hover,
.f\:bg-success-100:focus {
  background-color: var(--color-success-100) !important;
}

.bg-success-400,
.h\:bg-success-400:hover,
.f\:bg-success-400:focus {
  background-color: var(--color-success-400) !important;
}

.bg-success-600,
.h\:bg-success-600:hover,
.f\:bg-success-600:focus {
  background-color: var(--color-success-600) !important;
}

.c-success,
.h\:c-success:hover,
.f\:c-success:focus {
  color: var(--color-success) !important;
}

.c-success-100,
.h\:c-success-100:hover,
.f\:c-success-100:focus {
  color: var(--color-success-100) !important;
}

.c-success-400,
.h\:c-success-400:hover,
.f\:c-success-400:focus {
  color: var(--color-success-400) !important;
}

.c-success-600,
.h\:c-success-600:hover,
.f\:c-success-600:focus {
  color: var(--color-success-600) !important;
}

.bc-success,
.h\:bc-success:hover,
.f\:bc-success:focus {
  border-color: var(--color-success) !important;
}

.bc-success-100,
.h\:bc-success-100:hover,
.f\:bc-success-100:focus {
  border-color: var(--color-success-100) !important;
}

.bc-success-400,
.h\:bc-success-400:hover,
.f\:bc-success-400:focus {
  border-color: var(--color-success-400) !important;
}

.bc-success-600,
.h\:bc-success-600:hover,
.f\:bc-success-600:focus {
  border-color: var(--color-success-600) !important;
}

// Neutral Classes
.bg-neutral-white,
.h\:bg-neutral-white:hover,
.f\:bg-neutral-white:focus {
  background-color: var(--color-neutral-white) !important;
}

.bg-neutral-black,
.h\:bg-neutral-black:hover,
.f\:bg-neutral-black:focus {
  background-color: var(--color-neutral-black) !important;
}

.bg-neutral-100,
.h\:bg-neutral-100:hover,
.f\:bg-neutral-100:focus {
  background-color: var(--color-neutral-100) !important;
}

.bg-neutral-200,
.h\:bg-neutral-200:hover,
.f\:bg-neutral-200:focus {
  background-color: var(--color-neutral-200) !important;
}

.bg-neutral-300,
.h\:bg-neutral-300:hover,
.f\:bg-neutral-300:focus {
  background-color: var(--color-neutral-300) !important;
}

.bg-neutral-400,
.h\:bg-neutral-400:hover,
.f\:bg-neutral-400:focus {
  background-color: var(--color-neutral-400) !important;
}

.bg-neutral-500,
.h\:bg-neutral-500:hover,
.f\:bg-neutral-500:focus {
  background-color: var(--color-neutral-500) !important;
}

.bg-neutral-600,
.h\:bg-neutral-600:hover,
.f\:bg-neutral-600:focus {
  background-color: var(--color-neutral-600) !important;
}

.bg-neutral-700,
.h\:bg-neutral-700:hover,
.f\:bg-neutral-700:focus {
  background-color: var(--color-neutral-700) !important;
}

.bg-neutral-800,
.h\:bg-neutral-800:hover,
.f\:bg-neutral-800:focus {
  background-color: var(--color-neutral-800) !important;
}

.c-neutral-white,
.h\:c-neutral-white:hover,
.f\:c-neutral-white:focus {
  color: var(--color-neutral-white) !important;
}

.c-neutral-black,
.h\:c-neutral-black:hover,
.f\:c-neutral-black:focus {
  color: var(--color-neutral-black) !important;
}

.c-neutral-100,
.h\:c-neutral-100:hover,
.f\:c-neutral-100:focus {
  color: var(--color-neutral-100) !important;
}

.c-neutral-200,
.h\:c-neutral-200:hover,
.f\:c-neutral-200:focus {
  color: var(--color-neutral-200) !important;
}

.c-neutral-300,
.h\:c-neutral-300:hover,
.f\:c-neutral-300:focus {
  color: var(--color-neutral-300) !important;
}

.c-neutral-400,
.h\:c-neutral-400:hover,
.f\:c-neutral-400:focus {
  color: var(--color-neutral-400) !important;
}

.c-neutral-500,
.h\:c-neutral-500:hover,
.f\:c-neutral-500:focus {
  color: var(--color-neutral-500) !important;
}

.c-neutral-600,
.h\:c-neutral-600:hover,
.f\:c-neutral-600:focus {
  color: var(--color-neutral-600) !important;
}

.c-neutral-700,
.h\:c-neutral-700:hover,
.f\:c-neutral-700:focus {
  color: var(--color-neutral-700) !important;
}

.c-neutral-800,
.h\:c-neutral-800:hover,
.f\:c-neutral-800:focus {
  color: var(--color-neutral-800) !important;
}

.bc-neutral-white,
.h\:bc-neutral-white:hover,
.f\:bc-neutral-white:focus {
  border-color: var(--color-neutral-white) !important;
}

.bc-neutral-black,
.h\:bc-neutral-black:hover,
.f\:bc-neutral-black:focus {
  border-color: var(--color-neutral-black) !important;
}

.bc-neutral-100,
.h\:bc-neutral-100:hover,
.f\:bc-neutral-100:focus {
  border-color: var(--color-neutral-100) !important;
}

.bc-neutral-200,
.h\:bc-neutral-200:hover,
.f\:bc-neutral-200:focus {
  border-color: var(--color-neutral-200) !important;
}

.bc-neutral-300,
.h\:bc-neutral-300:hover,
.f\:bc-neutral-300:focus {
  border-color: var(--color-neutral-300) !important;
}

.bc-neutral-400,
.h\:bc-neutral-400:hover,
.f\:bc-neutral-400:focus {
  border-color: var(--color-neutral-400) !important;
}

.bc-neutral-500,
.h\:bc-neutral-500:hover,
.f\:bc-neutral-500:focus {
  border-color: var(--color-neutral-500) !important;
}

.bc-neutral-600,
.h\:bc-neutral-600:hover,
.f\:bc-neutral-600:focus {
  border-color: var(--color-neutral-600) !important;
}

.bc-neutral-700,
.h\:bc-neutral-700:hover,
.f\:bc-neutral-700:focus {
  border-color: var(--color-neutral-700) !important;
}


.bc-neutral-800,
.h\:bc-neutral-800:hover,
.f\:bc-neutral-800:focus {
  border-color: var(--color-neutral-800) !important;
}

.bc-transparent,
.h\:bc-transparent:hover,
.f\:bc-transparent:focus {
  border-color: transparent !important;
}

.bg-transparent,
.h\:bg-transparent:hover,
.f\:bg-transparent:focus {
  background-color: transparent !important;
}

.bc-inherit,
.h\:bc-inherit:hover,
.f\:bc-inherit:focus {
  border-color: inherit !important;
}

.bg-inherit,
.h\:bg-inherit:hover,
.f\:bg-inherit:focus {
  border-color: inherit !important;
}

.c-inherit,
.h\:c-inherit:hover,
.f\:c-inherit:focus {
  color: inherit !important;
}
