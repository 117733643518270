:root {
  --color-primary: #ffc857;
  --color-primary-100: #fffaf3;
  --color-primary-200: #fff5e7;
  --color-primary-300: #ffebcd;
  --color-primary-400: #ffe0af;
  --color-primary-500: #ffd48a;
  --color-primary-600: #ffc857;
  --color-primary-700: #e4b34e;
  --color-primary-800: #c69b43;
  --color-primary-900: #a17e37;
  --color-primary-1000: #725927;

  --color-secondary: #167c80;
  --color-secondary-100: #e8f2f3;
  --color-secondary-200: #d0e0e1;
  --color-secondary-300: #3193ad;
  --color-secondary-400: var(--color-secondary);
  --color-secondary-500: #126568;
  --color-secondary-600: #007055;
  --color-secondary-700: #004a38;
  --color-secondary-800: #545c52;

  --color-tertiary: #8e5cd1;
  --color-tertiary-100: #f6f1fd;
  --color-tertiary-200: #c9badc;
  --color-tertiary-400: var(--color-tertiary);
  --color-tertiary-600: #64468b;

  --color-blue: #2962ff;
  --color-blue-100: #dee7ff;
  --color-blue-400: var(--color-blue);

  --color-warning: #b11030;
  --color-warning-100: #fae6e8;
  --color-warning-200: #f4cace;
  --color-warning-400: var(--color-warning);
  --color-warning-600: #68000d;

  --color-success: #2f943b;
  --color-success-100: #e6fae8;
  --color-success-400: var(--color-success);
  --color-success-600: #00600c;

  --color-neutral-white: #ffffff;
  --color-neutral-black: #15130e;
  --color-neutral-100: #f9f9f9;
  --color-neutral-200: #f2f2f2;
  --color-neutral-300: #e5e5e4;
  --color-neutral-400: #c8c7c6;
  --color-neutral-500: #a5a5a3;
  --color-neutral-600: #797875;
  --color-neutral-700: #58554c;
  --color-neutral-800: #2e2a1f;
  --color-neutral-900: #242118;
  --color-neutral-1000: #15130e;

  --link-color: var(--color-neutral-black);
  --link-color-hover: var(--color-primary-1000);
  --link-color-active: var(--color-primary-1000);
  --link-color-visited: var(--color-primary-1000);
  --link-color-focus: var(--color-primary-1000);
}
