@use '../../mixins/form-controls';
@use '../../../common/tools/mixins/grid';

////
/// @group form-controls
////

/// @example
///   <label class="input">
///     <input class="input__input" type="text" placeholder=" ">
///     <span class="input__label">Label</span>
///   </label>
.input {
  @include form-controls.form-wrapper();

  &__control {
    @include form-controls.form-control('.input');
  }

  &__label {
    @include form-controls.form-label('.input');
  }
}
