.switch {
  --switch-checked-string: 'On';
  --switch-unchecked-string: 'Off';
  /* private */
  --_switch-width: 4.5rem;
  --_switch-height: 2.25rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:focus:not(:disabled) {
    .switch__control {
      box-shadow: 0 0 0 3px var(--color-primary);
      outline: 1px solid var(--color-neutral-700);
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 3px var(--color-primary);
    outline: 1px solid var(--color-neutral-700);
  }

  &:focus-within {
    .switch__control {
      box-shadow: 0 0 0 3px var(--color-primary);
      outline: 1px solid var(--color-neutral-700);
    }
  }

  &:has(.switch__control:disabled) {
    color: var(--color-neutral-500);
    cursor: not-allowed;

    .switch__control {
      border-bottom-color: var(--color-neutral-500);
      background-color: var(--color-neutral-300);
      cursor: not-allowed;

      &::after {
        border-bottom-color: var(--color-neutral-500);
        color: var(--color-neutral-500);
      }

      &::before {
        border-bottom-color: var(--color-neutral-500);
        background-color: var(--color-neutral-500);
      }

      &:checked {
        &::before {
          filter: grayscale(60%);
        }
      }
    }
  }

  &__label {
    margin-right: var(--space-100);
    line-height: var(--line-height-reset);
    user-select: none;
  }

  &__control {
    position: relative;
    width: var(--_switch-width);
    height: var(--_switch-height);
    border-radius: var(--border-radius-300);
    border-bottom: 2px solid var(--color-neutral-500);
    appearance: none;
    background-color: var(--color-neutral-400);
    cursor: pointer;
    transition: all var(--motion-duration-mid);

    &::after {
      display: block;
      margin-top: calc(var(--_switch-height) - 43%);
      margin-left: calc(var(--_switch-width) + 12px);
      color: var(--color-neutral-black);
      content: var(--switch-unchecked-string);
      font-size: var(--font-size-100);
      font-weight: var(--font-weight-semi-bold);
      white-space: nowrap;
    }

    &::before {
      position: absolute;
      top: 5px;
      left: 5px;
      display: block;
      width: 1.625rem;
      height: 1.625rem;
      border-radius: var(--border-radius-200);
      border-bottom: 2px solid var(--color-neutral-500);
      background-color: var(--color-neutral-white);
      content: '';
      transition: all var(--motion-duration-fast);
    }

    &:checked {
      border-bottom: 2px solid var(--color-primary-800);
      background-color: var(--color-primary);

      &::after {
        content: var(--switch-checked-string);
      }

      &::before {
        position: absolute;
        top: 5px;
        display: block;
        border-radius: var(--border-radius-200);
        border-bottom: 2px solid var(--color-primary-800);
        background-color: var(--color-neutral-white);
        content: ' ';
        transform: translateX(2.25rem);
      }
    }
  }

  &--with-icon {
    .switch__control {
      &:checked {
        &::before {
          content: url("data:image/svg+xml,%3Csvg width='25' height='23' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.3327 10.2917C16.3327 14.25 13.5619 16.2292 10.2685 17.3771C10.0961 17.4356 9.90873 17.4328 9.7381 17.3692C6.43685 16.2292 3.66602 14.25 3.66602 10.2917V4.75003C3.66602 4.54007 3.74942 4.3387 3.89789 4.19024C4.04636 4.04177 4.24772 3.95836 4.45768 3.95836C6.04102 3.95836 8.02018 3.00836 9.39768 1.80503C9.5654 1.66174 9.77875 1.58301 9.99935 1.58301C10.2199 1.58301 10.4333 1.66174 10.601 1.80503C11.9864 3.01628 13.9577 3.95836 15.541 3.95836C15.751 3.95836 15.9523 4.04177 16.1008 4.19024C16.2493 4.3387 16.3327 4.54007 16.3327 4.75003V10.2917Z' fill='%23FFC857' stroke='%23242118' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 17.4163V1.58301V17.4163Z' fill='%23FFC857'/%3E%3Cpath d='M10 17.4163V1.58301' stroke='%23242118' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  &--vertical {
    flex-direction: column;
    align-items: flex-start;

    >.switch__label {
      margin-bottom: var(--space-200);
    }
  }
}
