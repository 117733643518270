@use '../../mixins/form-controls';
@use '../../../common/tools/mixins/grid';

////
/// @group form-controls
////

/// @example
///   <label class="textarea">
///     <textarea class="textarea__control" placeholder=" "></textarea>
///     <span class="textarea__label">Label</span>
///   </label>
.textarea {
  @include form-controls.form-wrapper();

  &__control {
    @include form-controls.form-control('.textarea', false);
    overflow: auto;
    min-height: 2.5rem;
    line-height: 1.25;
    resize: vertical;
  }

  &__label {
    @include form-controls.form-label('.textarea');
  }
}
