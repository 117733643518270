:root {
  --space-base: 0.5rem;
  --space-0: calc(var(--space-base) * 0);
  --space-50: calc(var(--space-base) * 0.25);
  --space-75: calc(var(--space-base) * 0.5);
  --space-100: calc(var(--space-base) * 1);
  --space-200: calc(var(--space-base) * 1.5);
  --space-300: calc(var(--space-base) * 2);
  --space-400: calc(var(--space-base) * 3);
  --space-500: calc(var(--space-base) * 4);
  --space-600: calc(var(--space-base) * 5);
  --space-700: calc(var(--space-base) * 6);
  --space-800: calc(var(--space-base) * 8);
  --space-900: calc(var(--space-base) * 10);
  --space-1000: calc(var(--space-base) * 12);
}
