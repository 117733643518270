@use '../../mixins/form-controls';
@use '../../../common/tools/mixins/grid';

////
/// @group form-controls
////

/// @example
///   <label class="select">
///     <select class="select__control">
///       <option value="">Select an item...</option>
///       <option value="1">1</option>
///       <option value="2">2</option>
///       <option value="3">3</option>
///     </select>
///     <span class="select__label">Label</span>
///   </label>
.select {
  @include form-controls.form-wrapper();

  &__control {
    @include form-controls.form-control('.select');
    padding-right: 2rem;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Cpath%20d%3D%22M0.5%201.75L6%207.25L11.5%201.75%22%20stroke%3D%22%2358554C%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%20%3C%2Fsvg%3E'); // see ./select-caret.svg for source svg file.
    background-position: right 1rem bottom 1.3rem;
    background-repeat: no-repeat;
    line-height: 1.25;

    &:disabled {
      background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Cpath%20d%3D%22M0.5%201.75L6%207.25L11.5%201.75%22%20stroke%3D%22%2358554C%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%20%3C%2Fsvg%3E');
    }

    .validated &:invalid,
    .select--invalid & {
      background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2212%22%20height%3D%229%22%20viewBox%3D%220%200%2012%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%3Cpath%20d%3D%22M0.5%201.75L6%207.25L11.5%201.75%22%20stroke%3D%22%2358554C%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%20%3C%2Fsvg%3E');
    }
  }

  &__label {
    @include form-controls.form-label('.select');
  }
}
