.link {
  color: var(--link-color);
  font-size: var(--font-size-body-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-body);
  text-decoration: underline;
}

.link:hover {
  color: var(--link-color-hover);
  text-decoration: underline;
}

.link:active {
  color: var(--link-color-active);
  text-decoration: underline;
}

.link:visited {
  color: var(--link-color-visited);
  text-decoration: underline;
}

.link:focus {
  color: var(--link-color-focus);
  text-decoration: underline;
}
