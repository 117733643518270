@forward './card';
@forward './buttons';
@forward './form-controls';
@forward './breadcrumbs';
@forward './pill';
@forward './switch';
@forward './table';
@forward './pagination';
@forward './button-toggle';
@forward './detail-list';
@forward './tabs-nav';
@forward './typography';
/* plop:component */
