:root {
  // Global Font Family
  --font-family-webfont: 'Inter', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  --font-family-system-sans: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  --font-family-default: 'Inter', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
  --font-family-system-mono: Menlo, Consolas, Monaco, Liberation Mono, Lucida Console, monospace;

  --font-family-body: var(--font-family-default);
  --font-family-header: var(--font-family-default);
  --font-family-public: var(--font-family-default);


  // Global Font Size
  --font-size-0: 0.75rem; // 12px
  --font-size-50: 0.875rem; // 14px
  --font-size-100: 1rem; // 16px
  --font-size-200: clamp(1.13rem, 0.17vw + 1.09rem, 1.25rem); // 18px - 20px
  --font-size-300: clamp(1.25rem, 1.163rem + 0.4348vw, 1.5rem); // 20px - 24px
  --font-size-400: clamp(1.5rem, 1.3243rem + 0.7207vw, 2rem); // 24px - 32px
  --font-size-500: clamp(1.5rem, 1.1522rem + 1.7391vw, 2.5rem); // 24px - 40px
  --font-size-600: clamp(2rem, 1.19vw + 1.76rem, 2.87rem); // 32px - 46px
  --font-size-700: clamp(2.25rem, 1.695vw + 1.91rem, 3.5rem); // 36px - 56px
  --font-size-800: clamp(2.375rem, 1.69vw + 2.03rem, 3.625rem); // 38px - 58px
  --font-size-900: clamp(2.875rem, 1.52vw + 2.57rem, 4rem); // 46px - 64px
  --font-size-1000: clamp(3.5rem, 1.35vw + 3.22rem, 4.5rem); // 56px - 72px

  // Body Font Size
  --font-size-body-xs: var(--font-size-0);
  --font-size-body-sm: var(--font-size-50);
  --font-size-body-md: var(--font-size-100);
  --font-size-body-lg: var(--font-size-200);

  // Heading Font Size
  --font-size-heading-xs: var(--font-size-100);
  --font-size-heading-sm: var(--font-size-100);
  --font-size-heading-md: var(--font-size-200);
  --font-size-heading-lg: var(--font-size-300);
  --font-size-heading-xl: var(--font-size-400);
  --font-size-heading-xxl: var(--font-size-500);

  //Display Font Size
  --font-size-display-sm: var(--font-size-600);
  --font-size-display-md: var(--font-size-900);
  --font-size-display-lg: var(--font-size-1000);
  --font-size-display-xl: var();

  // Global Font Weight
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  // Aliased Font Weight
  --font-weight-regular: var(--font-weight-400);
  --font-weight-semi-bold: var(--font-weight-600);
  --font-weight-bold: var(--font-weight-700);

  // Global Line Height
  --line-height-0: 1;
  --line-height-100: 1.2;
  --line-height-200: 1.25;
  --line-height-300: 1.4;


  // Aliased Line Height
  --line-height-body: var(--line-height-300);
  --line-height-heading: var(--line-height-200);
  --line-height-sub-heading: var(--line-height-100);
  --line-height-reset: var(--line-height-0);
}
