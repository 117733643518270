:root {
  --elevation-bottom: -9999;
  --elevation-top: 9999;
  --elevation-0: 0;
  --elevation-100: 100;
  --elevation-200: 200;
  --elevation-300: 300;
  --elevation-400: 400;
  --elevation-500: 500;
  --elevation-600: 600;
  --elevation-700: 700;
  --elevation-800: 800;
  --elevation-900: 900;
  --elevation-1000: 1000;
}
