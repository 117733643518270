.form-help {
  display: block;
  margin-top: var(--space-75);
  color: var(--color-neutral-black);
  font-size: var(--font-size-body-sm);
  font-weight: var(--font-weight-regular);

  &--valid {
    color: var(--color-secondary);
  }

  &--invalid {
    color: var(--color-warning);
  }
}
